import React, { useState, useEffect } from "react";
import { FadeIn } from "react-slide-fade-in";
import axios from "axios";
import Logo from "../Assets/Img/image4.png";
import { SocialIcon } from "react-social-icons";

const Blog = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@fameapp"
      )
      .then((response) => {
        setArticles(response.data.items);
      })
      .catch((error) => {
        console.error("Error fetching the Medium articles: ", error);
      });
  }, []);

  const extractImageUrl = (description) => {
    const match = description.match(/<img[^>]+src="([^">]+)"/);
    return match ? match[1] : null;
  };

  const extractFirstParagraph = (description) => {
    const match = description.match(/<p>(.*?)<\/p>/);
    if (match) {
      const firstParagraph = match[1];
      // Remove any HTML tags from the extracted text
      return firstParagraph.replace(/<\/?[^>]+(>|$)/g, "");
    }
    return "";
  };

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <section>
        <div
          className="isolate bg-white dao-hero lg:bg-center bg-center"
          style={{ backgroundRepeat: "no-repeat" }}
        >
          <main className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="">
              <div className="p-10 lg:p-20 md:p-20 ">
                <div className="grid grid-cols-2">
                  <div className="items-end flex">
                    <div>
                      <div className="lg:flex flex-wrap space-x-5 hidden md:flex ">
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={0}
                        >
                          <SocialIcon
                            url="https://www.facebook.com/AlgorandFAME"
                            bgColor="#FFFFFF"
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={100}
                        >
                          <SocialIcon
                            url="https://www.instagram.com/algofame/"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={200}
                        >
                          <SocialIcon
                            url="https://www.linkedin.com/company/algofame"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={300}
                        >
                          <SocialIcon
                            url="https://youtube.com/@AlgoFame"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={300}
                        >
                          <SocialIcon
                            url="https://twitter.com/algorandFAME"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                      </div>
                      <div className="lg:flex flex-wrap space-x-5 mt-5 hidden md:flex">
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={400}
                        >
                          <SocialIcon
                            url="https://github.com/ALGOFAME"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={500}
                        >
                          <SocialIcon
                            url="https://t.me/algoFAME"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={600}
                        >
                          <SocialIcon
                            url="https://www.tiktok.com/@algofame"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={700}
                        >
                          <SocialIcon
                            url="https://discord.gg/EdktE8ZwYe"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                        <FadeIn
                          from="left"
                          positionOffset={200}
                          triggerOffset={200}
                          delayInMilliseconds={800}
                        >
                          <SocialIcon
                            url="https://medium.com/@fameapp"
                            bgColor="#FFFFFF"
                            className=""
                          />
                        </FadeIn>
                      </div>
                    </div>
                  </div>
                  <div className="pt-8">
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white lg:pb-0 md:pb-0 pb-0 lg:pt-0 md:pb-0 pt-16 text-right hero-heading">
                        news.
                      </p>
                    </FadeIn>
                    <FadeIn
                      from="right"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <h6 className="hero-list mt-10 text-right text-white hidden md:block lg:block">
                        <span>work.</span>
                        <span> sell.</span>
                        <span> borrow.</span>
                        <span> earn.</span>
                        <span> CONNECT.</span>
                      </h6>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
      <section>
        <div className="p-10 lg:p-20">
          <div className=" grid lg:grid-cols-3 gap-10">
            {articles.map((article, index) => (
              <div key={index} className="text-left">
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                  {extractImageUrl(article.description) && (
                    <img
                      src={extractImageUrl(article.description)}
                      alt={article.title}
                      className="w-full h-48 object-cover"
                    />
                  )}
                  <a
                    href={article.link}
                    className="text-blue-500 hover:underline p-4 inline-block hover:no-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 className="text-xl font-semibold text-left">
                      {article.title}
                    </h2>
                    <p className="text-[#0000008a] text-left mt-2 text-base">
                      {truncateText(
                        extractFirstParagraph(article.description),
                        120
                      )}
                    </p>
                    <div className="flex text-left justify-left mt-5 ">
                      <img
                        src={Logo}
                        alt="AlgoFame Logo"
                        className="h-10 w-10 rounded-full object-cover"
                      />
                      <div>
                        <p className="text-[#0000008a] text-sm text-left ml-3">
                          Admin
                        </p>
                        <p className="text-[#0000008a] text-sm text-left  ml-3">
                          {formatDate(article.pubDate)}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
