import React from "react";
import { SocialIcon } from "react-social-icons";

export default function Team({
  person,
  position,
  picture,
  content1,
  content2,
  content3,
  content4,
  url,
}) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <div className="grid grid-cols-5" onClick={() => setShowModal(true)}>
        <img
          className="col-span-2 person-image transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
          src={picture}
          alt=""
        />
        <div className="col-span-3 text-left mt-2 ml-3 center">
          <p className="team-name">{person}</p>
          <p className="team-position">{position}</p>
          {url && (
            <SocialIcon
              url={url}
              bgColor="#0077B5"
              style={{ width: "30px", height: "30px" }}
            />
          )}
        </div>
      </div>
      {showModal ? (
        <>
          <div className=" p-4 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl h-auto">
              {/*content*/}
              <div className=" rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <p className="person-name m-5">
                    {person}
                    <br />
                    <span className="person-position">{position}</span>
                  </p>
                  <div>
                    <button
                      className="p-1 ml-auto border-0 text-primary  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="text-primary focus:outline-none">
                        &#10006;
                      </span>
                    </button>
                    <img
                      src={picture}
                      alt=""
                      className="p-1  text-primary person-image float-right outline-none focus:outline-none"
                    />
                  </div>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 about-text leading-relaxed mb-5">
                    {content1}
                  </p>
                  <p className="my-4 about-text leading-relaxed mb-5">
                    {content2}
                  </p>
                  <p className="my-4 about-text leading-relaxed mb-5">
                    {content3}
                  </p>
                  <p className="my-4 about-text leading-relaxed mb-5">
                    {content4}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
