import "./App.css";
import React from "react";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Error from "./Pages/404";
import Terms from "./Pages/Terms";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Dao from "./Pages/Dao";
import Connect from "./Pages/Connect";
import LocalDao from "./localDao/Pages/Dao";
import FrenchLeague from "./frenchLeague/Pages/Dao";
import EnuguLeague from "./enuguLeague/Pages/Dao";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import {
  reconnectProviders,
  initializeProviders,
  WalletProvider,
} from "@txnlab/use-wallet";
import Dashboard from "./Pages/Dashboard";
import Analytics from "./Pages/Analytics";
import Bridge from "./Pages/Bridge";
import { ConstantProvider } from "./Controller/Constants";
import { LocalConstantProviders } from "./localDao/Controller/Constants";
import { FrenchConstantProviders } from "./frenchLeague/Controller/Constants";
import { EnuguConstantProviders } from "./enuguLeague/Controller/Constants";
import DaoNav from "./Components/DaoNav";
import FranceDaoNav from "./frenchLeague/Components/DaoNav";
import EnuguDaoNav from "./enuguLeague/Components/DaoNav";

import { toast, ToastContainer } from "react-toastify";
import Blog from "./Pages/Blog";

function App() {
  const walletProviders = initializeProviders(["pera"]); // Initialize only "pera" provider

  React.useEffect(() => {
    reconnectProviders(walletProviders);
  }, []);

  return (
    <ConstantProvider>
      <ToastContainer />
      <WalletProvider value={walletProviders}>
        <div className="App">
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                <Route
                  path="/"
                  element={
                    <div>
                      <Navbar />
                      <Home />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <div>
                      <Navbar />
                      <About />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="/termsofservice"
                  element={
                    <div>
                      <Navbar />
                      <Terms />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="/dao"
                  element={
                    <div>
                      <DaoNav />
                      <Dao />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="/leagues/fame"
                  element={
                    <LocalConstantProviders>
                      <div>
                        <DaoNav />
                        <LocalDao />
                        <Footer />
                      </div>
                    </LocalConstantProviders>
                  }
                />
                <Route
                  path="/leagues/algorandfrance"
                  element={
                    <FrenchConstantProviders>
                      <div>
                        <FranceDaoNav />
                        <FrenchLeague />
                        <Footer />
                      </div>
                    </FrenchConstantProviders>
                  }
                />
                <Route
                  path="/leagues/enuguleague"
                  element={
                    <EnuguConstantProviders>
                      <div>
                        <EnuguDaoNav />
                        <EnuguLeague />
                        <Footer />
                      </div>
                    </EnuguConstantProviders>
                  }
                />
                <Route
                  path="/news"
                  element={
                    <div>
                      <Navbar />
                      <Blog />
                      <Footer />
                    </div>
                  }
                />

                <Route
                  path="/connect-wallet"
                  element={
                    <div>
                      <DaoNav />
                      <Connect />
                    </div>
                  }
                />
                <Route
                  path="*"
                  element={
                    <div>
                      <Navbar />
                      <Error />
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <div>
                      <Dashboard />
                    </div>
                  }
                />
                <Route
                  path="/analytics"
                  element={
                    <div>
                      <Analytics />
                    </div>
                  }
                />
                <Route
                  path="/bridge"
                  element={
                    <div>
                      <Bridge />
                    </div>
                  }
                />
                {/* <Route path="/portuguese" element="https://pg.algofame.org" /> */}
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </div>
      </WalletProvider>
    </ConstantProvider>
  );
}

export default App;
